<template>
  <v-container fluid class="py-7">
    <v-row v-if="$vuetify.breakpoint.mdAndUp">
      <template v-for="(feature, i) in features">
        <v-col
          v-if="i === 0 || i === 1 || i === 4 || i === 5 || i === 8 || i === 9"
          id="color"
          class="my-0 py-0"
          :key="i"
          cols="12"
          sm="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="justify"
            horizontal
            customText="altConsulting"
            :image="feature.image"
          />
        </v-col>
        <v-col v-else class="ma-0 pa-0" :key="i" cols="12" sm="6">
          <base-avatar-card
            v-bind="feature"
            align="justify"
            horizontal
            customText="altConsulting"
            :image="feature.image"
          />
        </v-col>
      </template>
    </v-row>
    <v-row v-else>
      <template v-for="(feature, j) in featuresMini">
        <v-col
          v-if="j === 0 || j === 2 || j === 4"
          id="color"
          class="my-0 py-0"
          :key="j"
          cols="12"
          sm="12"
        >
          <base-avatar-card
            v-bind="feature"
            align="justify"
            horizontal
            customText="altConsulting"
            :image="feature.image"
          />
        </v-col>
        <v-col v-else class="my-0 py-0" :key="j" cols="12" sm="12">
          <base-avatar-card
            v-bind="feature"
            align="justify"
            horizontal
            customText="altConsulting"
            :image="feature.image"
          />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AlternatingFeatures",
  computed: {
    featuresMini() {
      var tempArray = this.features;
      var indexes = [1, 2, 5, 6, 9, 10];
      tempArray = tempArray.filter(function (value, index) {
        return indexes.indexOf(index) == -1;
      });
      return tempArray;
    },
  },

  data: () => ({
    features: [
      {
        title: "Offshore Centers - Technology and Non-Investment Verticals",
        text: "We assist our clients in establishing strategic dedicated development centers that enable them to scale horizontally and meet the growing demands of their business. Our offshore centers offer a range of services, including software development, quality assurance, data analytics, and business process outsourcing, among others, to help clients achieve their business objectives. With the ability to set up your own dedicated team, our offshore centers offer a valuable resource for clients seeking to optimize their operations and enhance their competitive advantage.",
      },
      {
        title: "",
        text: "",
        image: "global-network",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "Data warehousing for Partnership & Portfolio accounting",
        text: "Build your proprietary data warehouse, Audited and Time-Series, with ELT or ETL designs. Define data governance protocols (security, quality, and availability). We provide investment firms with a centralized repository to manage and analyze vast amounts of financial data. By leveraging these solutions, firms can streamline operations, reduce errors, and achieve regulatory compliance. Implementation of data warehousing solutions can provide investment firms with a competitive advantage by optimizing their operations and driving performance.",
      },
      {
        title: "Fund Admin Onboarding and Integration",
        text: "Our Fund admin onboarding and integration services facilitate the seamless integration of new funds into an investment firm's platform by leveraging cutting-edge technological solutions. These services encompass critical activities such as data mapping, system configuration, and rigorous testing protocols to ensure a robust and efficient onboarding process. By leveraging these services, investment firms can achieve significant operational efficiencies, reduce turnaround times, enhance data accuracy, and maintain compliance with regulatory requirements. Ultimately, this translates into improved performance, greater client satisfaction, and a competitive edge in the financial services landscape.",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "Data Analytics & BI",
        text: "Our Business Intelligence (BI) services offer investment firms the ability to gain actionable insights from their financial data, enabling them to make informed decisions and stay ahead of the competition. Our BI solutions encompass advanced analytics, data visualization, and reporting, providing investment firms with a holistic view of their business operations. By leveraging our BI services, investment firms can optimize their performance, identify new opportunities, and gain a competitive edge in the financial services landscape, ultimately driving growth and profitability.",
      },
      {
        title: "Bespoke Product/App Development",
        text: "Our bespoke application and app development services cater specifically to the needs of alternate asset managers, providing tailored solutions to meet their unique business requirements. Our team of experienced developers works closely with clients to design and build custom applications, delivering seamless user experiences and optimized performance. By leveraging our bespoke application and app development services, alternate asset managers can enhance their operations, improve efficiency, and gain a competitive advantage in the dynamic financial services industry.",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "API Enabled Infrastructure",
        text: "We provide solutions to enable the creation of a transparent and dynamic API ecosystem, utilizing cutting-edge technology to automatically scale infrastructure and minimize process redundancies. We optimize your API infrastructure for high performance and cost efficiency, allowing you to focus on delivering value to your users. With our solution, you can seamlessly scale your infrastructure while maintaining the highest standards of transparency and reliability, ultimately driving growth and profitability for your business.",
      },
    ],
  }),
};
</script>
<style scoped>
#color {
  background-color: #ffffff;
  font-size: 1.9rem;
}

.v-col {
  font-size: 50px;
}
</style>
